import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";

//COMPONENTS
import Hero from '../components/Hero';
import ContactBanner from '../components/ContactBanner';
import NewsSection from '../components/News/NewsSection';
import SEO from '../components/SEO';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';

const StyledAtAGlance = styled.main`
  margin: 0 auto;
  max-width: 1500px;
  .max-width {
    max-width: 1500px;
    margin: 0 auto;
  }
  .intro-copy {
    color: #757575;
  }
`;

class AtAGlance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPreviewMode: false,
      previewPayload: null,
      isOpen: false
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------- -----------------
    */
  }
  render() {
    let glance_page = this.props.data.wordpressPage;

    if (this.state.isPreviewMode) {
      glance_page.acf = this.state.previewPayload.acf;
    }

    return (
      <StyledAtAGlance className="animate">
        <SEO
          isBlogPost={false}
          postData={glance_page}
          postImage={DefaultSharingImage}
        />
        <Hero
          headline={glance_page.acf.hero_headline}
          sub={glance_page.acf.hero_sub_headline}
        />
        <div className="page-container max-width">
          <div className="intro-copy">
            {ReactHtmlParser(glance_page.acf.intro_copy)}
          </div>
          <img
            src={glance_page.acf.infographic.link}
            alt="The Evolution of Ansira"
          />
        </div>
        <NewsSection />
        <div className="about-container">
          <ContactBanner
            headline={glance_page.acf.contact_headline}
            cta={glance_page.acf.contact_cta}
            url={glance_page.acf.contact_url}
          />
        </div>
      </StyledAtAGlance>
    );
  }
}

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "at-a-glance" }) {
      content
      slug
      title
      type
      content
      acf {
        hero_headline
        hero_sub_headline
        intro_copy
        contact_headline
        contact_cta
        contact_url
        infographic {
          link
        }
        seo_title
        seo_canonical
        seo_description
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default AtAGlance;
