import React from 'react';
import styled from 'styled-components';
import { Link, StaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
//vars
import { colors, breakpoints, fonts } from '../../style-utilities/variables';
//comps
import NewsGridItem from './NewsGridItem';
import ButtonRed from '../../components/ButtonRed';

const StyledNewsSection = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 5rem 0 7rem;
  .news-header {
    border-top: 1px solid #dbdcdd;
    width: 100%;
    text-align: center;
    h2 {
      margin-bottom: 0;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-items {
      width: 100%;
    }
  }
`;
const StyledArticleContainer = styled.div`
  display: -ms-grid;
  display: grid;
  grid-gap: 1em;
  margin-top: 0em;
  margin-right: auto;
  margin-bottom: 1.45em;
  margin-left: auto;

  @media (min-width: ${breakpoints.tablet}) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    div {
      margin-right: 1rem;
    }
    div:last-child {
      margin-right: 0;
    }
  }
`;

const NewsSection = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpNews {
            edges {
              node {
                title
                date(formatString: "MMMM D, YYYY")
                slug
                acf {
                  excerpt
                  hero_headline
                  hero_sub_headline
                }
              }
            }
          }
        }
      `}
      render={data => {
        const allNews = data.allWordpressWpNews.edges;
        const filteredNews = allNews.filter(
          post => post.node.slug !== 'do-not-delete'
        );

        return (
          <StyledNewsSection className="page-container">
            <h3>Latest News</h3>
            <StyledArticleContainer className="news-items">
              {filteredNews.slice(0, 3).map(({ node }, index) => {
                return (
                  <NewsGridItem
                    key={node.slug}
                    item={node}
                    index={'/about/news'}
                  />
                );
              })}
            </StyledArticleContainer>
            <ButtonRed
              textLabel="See All News"
              pageLink="/about/news"
              id="btn-seeallnews"
            />
          </StyledNewsSection>
        );
      }}
    />
  );
};

export default NewsSection;
