import React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { colors, fonts, breakpoints } from '../../style-utilities/variables';

import ButtonGrey from '../../components/ButtonGrey';

const StyledNewsItem = styled.div`
  flex: 1;
  background-color: ${colors.white};
  display: flex;
  border: 2px solid ${colors.secondaryGray};
  flex-direction: column;
  &:first-of-type,
  &:nth-of-type(2) {
    margin-top: 0;
  }
  img {
    margin-bottom: 0;
  }
  a {
    color: black;
  }
  .image-box {
    width: 100%;
    padding: 1em;
    min-height: 300px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-bottom: 0;
    }
  }
  .item-content {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .date {
      color: ${colors.primaryGray};
      font-size: 0.9rem;
      margin-bottom: 0.8em;
    }
    .title-link {
      color: ${colors.black};
      font-weight: bold;
      font-size: 22px;
      text-decoration: none;
      line-height: 26px;
      font-family: ${fonts.subHead};
      margin-bottom: 1em;
      :hover {
        text-decoration: underline;
      }
    }
    .excerpt {
      flex: 1 1 auto;
    }
  }
`;

const NewsGridItem = ({ item, index, className, type }) => {
  if (className === 'last') {
    return (
      <StyledNewsItem key={item.slug}>
        <div className="item-content">
          <div className="date">{item.date}</div>
          <Link to={'about/news/' + item.slug} className="title-link">
            {item.title}
          </Link>
          {item.acf.excerpt ? (
            <div className="excerpt">{ReactHtmlParser(item.acf.excerpt)}</div>
          ) : (
            <div className="excerpt">{ReactHtmlParser(item.excerpt)}</div>
          )}
          <div>
            {item.slug ? (
              <ButtonGrey
                textLabel="READ MORE"
                pageLink={'about/news/' + item.slug}
              />
            ) : (
              <ButtonGrey textLabel="READ MORE" externalLink={item.acf.link} />
            )}
          </div>
        </div>
      </StyledNewsItem>
    );
  } else {
    return (
      <StyledNewsItem key={item.slug}>
        {item.type === 'press' ? (
          <a class="image-box" href={item.acf.link} target="_blank">
            <img src={item.better_featured_image.source_url} />
          </a>
        ) : (
          <></>
        )}
        <div className="item-content" style={{ flexGrow: '1' }}>
          <div className="date">{item.date}</div>
          {item.type === 'press' ? (
            <a class="title-link" href={item.acf.link} target="_blank">
              {item.title}
            </a>
          ) : (
            <Link to={'about/news/' + item.slug} className="title-link">
              {item.title}
            </Link>
          )}

          {item.acf.excerpt ? (
            <div className="excerpt">{ReactHtmlParser(item.acf.excerpt)}</div>
          ) : (
            <div className="excerpt">{ReactHtmlParser(item.excerpt)}</div>
          )}
          <div>
            {item.slug ? (
              <ButtonGrey
                textLabel="READ MORE"
                pageLink={'about/news/' + item.slug}
              />
            ) : (
              <ButtonGrey textLabel="READ MORE" externalLink={item.acf.link} />
            )}
          </div>
        </div>
      </StyledNewsItem>
    );
  }
};

export default NewsGridItem;
